const PRIMARY_COLOR = '#45aeeb';
const SECONDARY_COLOR = '#58b6ed';
const BACKGROUND_COLOR = '#fff';
const BLACK_COLOR = '#1a1a1a';
const RED_COLOR = 'red';
const GREY_COLOR = 'grey';
const OFF_WHITE_BACKGROUND = '#f7f9fa';
const DARK_BLUE_COLOR = '#2e709d';
const PRIMARY_FONT = 'CircularStd, sans-serif';
const SECONDARY_FONT = 'Apercu-Mono, monospace';
const TERTIARY_FONT = 'Inter, sans-serif';
const QUATERNARY_FONT = 'DM Sans, sans-serif';
const WHITE_COLOR = '#ffffff';

const PRIMARY300 = '#96D1F4';
const GREY_100 = '#F9F9F9';
const GREY_200 = '#e1e1e1';
const GREY_300 = '#EEEEEE';
const GREY_400 = '#B2B2B2';
const GREY_500 = '#BBBBBB';
const GREY_600 = '#999999';
const GREY_700 = '#565656';
const GREY_800 = '#343434';
const MEDIUM_GREY = '#6C6C6C';
const NEW_BLACK_COLOR = '#111111';
const PITCH_BLACK = '#000000';

const theme = {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BACKGROUND_COLOR,
  BLACK_COLOR,
  RED_COLOR,
  GREY_COLOR,
  OFF_WHITE_BACKGROUND,
  DARK_BLUE_COLOR,
  PRIMARY_FONT,
  SECONDARY_FONT,
  TERTIARY_FONT,
  QUATERNARY_FONT,
  WHITE_COLOR,
  NEW_BLACK_COLOR,
  PRIMARY300,
  PITCH_BLACK,
  GREY_100,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_500,
  GREY_600,
  GREY_700,
  GREY_800,
  MEDIUM_GREY,
};

export default theme;
