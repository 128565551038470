import styled from 'styled-components';
import theme from '../../util/theme';
import { mobileSize } from '../../util/variables';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 80px 0;
  background-color: ${theme.GREY_300};
  @media ${mobileSize} {
    margin: 48px 0;
  }
`;
