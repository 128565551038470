/* eslint-disable react/function-component-definition */
import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { mobileSize } from '../../util/variables';

export interface ModalProps {
  showModal: boolean;
  label: string;
  toggleModal: () => void;
  children: React.ReactNode;
  height?: string;
  width?: string;
  zIndex?: string;
  background?: string;
  backgroundColor?: string;
}

export interface ModalState {}

class Modal extends React.Component<ModalProps, ModalState> {
  public render(): JSX.Element {
    const {
      showModal,
      label,
      toggleModal,
      children,
      height,
      width,
      zIndex = '1200',
      background = '#fff',
      backgroundColor = '#1a1a1a',
      ...props
    } = this.props;

    return (
      <StyledModal
        shouldCloseOnOverlayClick={false}
        isOpen={showModal}
        contentLabel={label}
        ariaHideApp={false}
        height={height}
        width={width}
        zIndex={zIndex}
        background={background}
        backgroundColor={backgroundColor}
        {...props}
        onRequestClose={toggleModal}
      >
        {children}
      </StyledModal>
    );
  }
}

function ReactModalAdapter({ className, children, ...props }: any) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
      children={children}
    />
  );
}

interface AdditionalModalProps {
  width?: '903px';
  height?: '475px';
  zIndex?: '10';
}

const StyledModal = styled(ReactModalAdapter)<AdditionalModalProps>`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${props => props.backgroundColor};
    z-index: ${props => props.zIndex};
    overflow-y: auto;
  }
  &__content {
    width: ${props => props.width};
    height: ${props => props.height};
    margin: auto;
    background: ${props => props.background};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @media ${mobileSize} {
      /* max-height: 600px; */
      overflow-y: auto;
    }
  }
`;

StyledModal.defaultProps = {
  width: 'fit-content',
  height: 'fit-content',
};

export { Modal };
