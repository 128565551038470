export const mobileSize = 'screen and (max-width: 768px)';
export const laptopSize = 'screen and (max-width: 992px)';
export const desktopSize = 'screen and (max-width: 1280px)';
export const smallMobileSize = 'screen and (max-width: 500px)';
export const salDuration = '500';
export const salTimingFunction = 'cubic-bezier(.455, .03, .515, .955)';
export const SHEET_BEST_API_KEY =
  '2Cndkyg7aXBP4_MyHggOpVyI!ZB5g69zt%Ig2!!BBG8z$vI3Rp3rjt_JLRv8oShT';
export const BACKEND_URL = 'https://api.base10.vc';
export const S3_COMPANY_IMAGE_BUCKET_BASE =
  'https://base10-company-images.s3-us-west-1.amazonaws.com';

export const PORTAL_BASE_URL = 'https://portal.base10.vc';
