import * as React from 'react';
import { salDuration, salTimingFunction } from '../../util/variables';

interface IAnimatedDivProps {
  delay?: string;
  sal?: string;
}

const AnimatedDiv: React.FunctionComponent<IAnimatedDivProps> = ({
  sal = 'slide-right',
  delay = '100',
  children,
  ...props
}) => {
  return (
    <div
      data-sal={sal}
      data-sal-delay={delay}
      data-sal-easing={salTimingFunction}
      data-sal-duration={salDuration}
    >
      {children}
    </div>
  );
};

export { AnimatedDiv };
