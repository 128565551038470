import styled from 'styled-components';
import theme from '../../util/theme';

export const AppTitle = styled.h1`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 30px;
  line-height: 1;
  color: ${theme.WHITE_COLOR};
`;
