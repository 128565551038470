import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BurgerIcon from './BurgerIcon';
import { Wrapper } from './Wrapper';
import theme from '../../util/theme';
import { mobileSize, desktopSize, laptopSize, PORTAL_BASE_URL } from '../../util/variables';
import { Base10Logo } from '../../svg/base10logo';

interface Props {
  invert: boolean;
  initiallyBlack?: boolean;
  /**
   * If true, only shows 2 option: HBCU Dataroom and Model Portfolio Construction
   * Only applicable when invert: false
   */
  hbcu?: boolean;
  anchorToHere?: boolean;
}

const NavBar: FunctionComponent<Props> = ({
  invert = false,
  initiallyBlack = false,
  hbcu = false,
  anchorToHere = false,
}) => {
  const [defaultState, setDefaultState] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollValue < -50) {
      setToChangedState();
    } else {
      setToDefaultState();
    }
  }, [scrollValue]);

  const handleScroll = () => {
    setScrollValue(document.body.getBoundingClientRect().top);
  };

  const setToDefaultState = () => {
    setDefaultState(true);
  };

  const setToChangedState = () => {
    setDefaultState(false);
  };

  const showBurgerNav = () => {
    setBurgerNav(!burgerNav);
  };

  return (
    <NavBarContainer defaultState={defaultState} invert={invert} initiallyBlack={initiallyBlack}>
      <NavBarWrapper>
        <Wrapper>
          <NavBarIconContainer>
            <Link to="/">
              <Base10Logo color={invert ? 'black' : 'white'} />
            </Link>
          </NavBarIconContainer>
          <MobileBurger>
            <BurgerIcon showNav={showBurgerNav} showNavState={burgerNav} invert={invert} />
          </MobileBurger>
          <MobileIcon onClick={showBurgerNav}>
            <Link to="/">
              <Base10Logo color={invert ? 'black' : 'white'} />
            </Link>
          </MobileIcon>
          <SideNavDiv showNav={burgerNav} invert={invert}>
            {anchorToHere ? (
              <NavigationContainer invert={invert}>
                <StyledLink to="/companies/" invert={invert}>
                  Companies
                </StyledLink>
                <StyledLink to="/team/" invert={invert}>
                  Team
                </StyledLink>
                {/* <StyledLink to="/research/" invert={invert}>
                  Research
                </StyledLink> */}
                <StyledLink to="/content/" invert={invert}>
                  Content
                </StyledLink>
                {/* <StyledLink to="/content/" invert={invert}>
                  Content
                </StyledLink> */}
                {/* <StyledLink to="/shopify">Shopify Ecosystem</StyledLink> */}
                {/* <StyledLink to="/esg">ESG</StyledLink> */}
              </NavigationContainer>
            ) : invert ? (
              <NavigationContainer invert={invert}>
                <OutsideLink href="https://base10.vc/companies/" invert={invert}>
                  Companies
                </OutsideLink>
                <OutsideLink href="https://base10.vc/team/" invert={invert}>
                  Team
                </OutsideLink>
                <OutsideLink href="https://base10.vc/research/" invert={invert}>
                  Research
                </OutsideLink>
                <OutsideLink href="https://base10.vc/content/" invert={invert}>
                  Content
                </OutsideLink>
                {/* <OutsideLink href="https://base10.vc/content/" invert={invert}>
                  Content
                </OutsideLink> */}
                {/* <OutsideLink href="https://portal.base10.vc/" invert={invert}>
                    LP Portal
                  </OutsideLink> */}
              </NavigationContainer>
            ) : hbcu ? (
              <NavigationContainer invert={invert}>
                <StyledLink
                  to={`${PORTAL_BASE_URL}/advancement-initiative-data-room`}
                  invert={invert}
                >
                  Advancement Initiative Dataroom
                </StyledLink>
                <StyledLink to="/advancement-initiative?aidr=true" invert={invert}>
                  Model Portfolio Construction
                </StyledLink>
                <StyledLink to={`${PORTAL_BASE_URL}/logout`} invert={invert}>
                  Logout
                </StyledLink>
              </NavigationContainer>
            ) : (
              <NavigationContainer invert={invert}>
                <StyledLink to="/companies/" invert={invert}>
                  Companies
                </StyledLink>
                <StyledLink to="/team/" invert={invert}>
                  Team
                </StyledLink>
                {/* <StyledLink to="/research/" invert={invert}>
                  Research
                </StyledLink> */}
                <StyledLink to="/content/" invert={invert}>
                  Content
                </StyledLink>
                {/* <StyledLink to="/content/" invert={invert}>
                  Content
                </StyledLink> */}
                {/* <StyledLink to="/shopify">Shopify Ecosystem</StyledLink> */}
                {/* <StyledLink to="/esg">ESG</StyledLink> */}
                {/* <StyledLink to="https://portal.base10.vc/" invert={invert}>
                    LP Portal
                  </StyledLink> */}
              </NavigationContainer>
            )}
          </SideNavDiv>
          <NavBarOptions>
            {anchorToHere ? (
              <SpaceBetweenDiv>
                <NavList>
                  <NavListItem>
                    <StyledLink to="/companies/" activeClassName="active" invert={invert}>
                      Companies
                    </StyledLink>
                  </NavListItem>
                  <NavListItem>
                    <StyledLink to="/team/" activeClassName="active" invert={invert}>
                      Team
                    </StyledLink>
                  </NavListItem>
                  {/* <NavListItem>
                    <StyledLink to="/research/" activeClassName="active" invert={invert}>
                      Research
                    </StyledLink>
                  </NavListItem> */}
                  <NavListItem>
                    <StyledLink to="/content/" activeClassName="active" invert={invert}>
                      Content
                    </StyledLink>
                  </NavListItem>
                  {/* <NavListItem>
                  <StyledLink to="/content/" activeClassName="active" invert={invert}>
                    Content
                  </StyledLink>
                </NavListItem> */}
                  {/* <NavListItem>
                            <StyledLink to="/shopify" activeClassName="active" invert={invert}>
                              Shopify Ecosystem
                            </StyledLink>
                          </NavListItem>
                          <NavListItem>
                            <StyledLink to="/esg">ESG</StyledLink>
                          </NavListItem> */}
                  {/* <NavListItem>
                  <StyledLink makeblue to="https://portal.base10.vc/" invert={invert}>
                    LP Portal
                  </StyledLink>
                </NavListItem> */}
                </NavList>
                <StyledLink to={PORTAL_BASE_URL} invert={invert}>
                  <LPPortalButton>Login</LPPortalButton>
                </StyledLink>
              </SpaceBetweenDiv>
            ) : invert ? (
              <SpaceBetweenDiv>
                <NavList>
                  <NavListItem>
                    <OutsideLink href="https://base10.vc/companies/" invert={invert}>
                      Companies
                    </OutsideLink>
                  </NavListItem>
                  <NavListItem>
                    <OutsideLink href="https://base10.vc/team/" invert={invert}>
                      Team
                    </OutsideLink>
                  </NavListItem>
                  <NavListItem>
                    <OutsideLink href="https://base10.vc/research/" invert={invert}>
                      Research
                    </OutsideLink>
                  </NavListItem>
                  <NavListItem>
                    <OutsideLink href="https://base10.vc/content/" invert={invert}>
                      Content
                    </OutsideLink>
                  </NavListItem>
                  {/* <NavListItem>
                  <OutsideLink href="https://base10.vc/content/" invert={invert}>
                    Press
                  </OutsideLink>
                </NavListItem> */}
                  {/* <NavListItem>
                <StyledLink to="/shopify" activeClassName="active" invert={invert}>
                  Shopify Ecosystem
                </StyledLink>
              </NavListItem>
              <NavListItem>
                <StyledLink to="/esg">ESG</StyledLink>
              </NavListItem> */}
                  {/* <NavListItem>
                    <OutsideLink makeblue href="https://portal.base10.vc/" invert={invert}>
                      LP Portal
                    </OutsideLink>
                  </NavListItem> */}
                </NavList>
                <OutsideLink makeblue href={PORTAL_BASE_URL} invert={invert}>
                  <LPPortalButton>Login</LPPortalButton>
                </OutsideLink>
              </SpaceBetweenDiv>
            ) : hbcu ? (
              <NavList>
                <NavListItem>
                  <StyledLink
                    to={`${PORTAL_BASE_URL}/advancement-initiative-data-room`}
                    invert={invert}
                  >
                    Advancement Initiative Dataroom
                  </StyledLink>
                </NavListItem>
                <NavListItemActive>
                  <StyledLink
                    to="/advancement-initiative?aidr=true"
                    activeClassName="active"
                    invert={invert}
                  >
                    Model Portfolio Construction
                  </StyledLink>
                </NavListItemActive>
                <NavListItem>
                  <StyledLink makeblue to={`${PORTAL_BASE_URL}/logout`} invert={invert}>
                    Logout
                  </StyledLink>
                </NavListItem>
              </NavList>
            ) : (
              <SpaceBetweenDiv>
                <NavList>
                  <NavListItem>
                    <StyledLink to="/companies/" activeClassName="active" invert={invert}>
                      Companies
                    </StyledLink>
                  </NavListItem>
                  <NavListItem>
                    <StyledLink to="/team/" activeClassName="active" invert={invert}>
                      Team
                    </StyledLink>
                  </NavListItem>
                  {/* <NavListItem>
                    <StyledLink to="/research/" activeClassName="active" invert={invert}>
                      Research
                    </StyledLink>
                  </NavListItem> */}
                  <NavListItem>
                    <StyledLink to="/content/" activeClassName="active" invert={invert}>
                      Content
                    </StyledLink>
                  </NavListItem>
                  {/* <NavListItem>
                  <StyledLink to="/content/" activeClassName="active" invert={invert}>
                    Press
                  </StyledLink>
                </NavListItem> */}
                  {/* <NavListItem>
                <StyledLink to="/shopify" activeClassName="active" invert={invert}>
                  Shopify Ecosystem
                </StyledLink>
              </NavListItem>
              <NavListItem>
                <StyledLink to="/esg">ESG</StyledLink>
              </NavListItem> */}
                  {/* <NavListItem>
                    <StyledLink makeblue to="https://portal.base10.vc/" invert={invert}>
                      LP Portal
                    </StyledLink>
                  </NavListItem> */}
                </NavList>
                <StyledLink makeblue to={PORTAL_BASE_URL} invert={invert}>
                  <LPPortalButton>Login</LPPortalButton>
                </StyledLink>
              </SpaceBetweenDiv>
            )}
          </NavBarOptions>
        </Wrapper>
      </NavBarWrapper>
    </NavBarContainer>
  );
};

const LPPortalButton = styled.button`
  width: 100px;
  height: 45px;
  padding: 8px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.2px;
  border-radius: 8px;
  background-color: ${theme.PRIMARY_COLOR};
  color: ${theme.WHITE_COLOR};
  transition: background-color 300ms ease-in-out;
  :hover {
    background-color: ${theme.PRIMARY300};
  }
  @media ${mobileSize} {
    display: none;
  }
`;

const SpaceBetweenDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 70px;
  @media ${desktopSize} {
    padding-left: 50px;
  }
  @media ${laptopSize} {
    padding-left: 0px;
  }
`;

interface ContainerState {
  defaultState: boolean;
  invert: boolean;
  initiallyBlack: boolean;
}

const NavBarContainer = styled.div<ContainerState>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: ${props => (props.defaultState ? '120px' : '60px')};
  background-color: ${props =>
    props.defaultState
      ? props.initiallyBlack
        ? theme.BLACK_COLOR
        : 'transparent'
      : props.invert
      ? theme.WHITE_COLOR
      : theme.BLACK_COLOR};
  transition: background-color 500ms, height 500ms, padding-top 0.25s, padding-bottom 0.25s,
    top 0.25s;
  @media ${mobileSize} {
    height: ${props => (props.defaultState ? '80px' : '55px')};
    background-color: ${props => (props.invert ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  }
  box-shadow: ${props => (props.defaultState ? 'none' : '0px 2px 3px 0px rgba(0, 0, 0, 0.3)')};
`;

const NavBarWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 1600px;
`;

const NavBarIconContainer = styled.div`
  width: calc(25% - 38px);
  margin-left: 30px;
  margin-top: 10px;
  float: left;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media ${mobileSize} {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1000;
    width: 100%;
    text-align: center;
  }
`;

const MobileBurger = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: absolute;
    left: 10px;
    z-index: 1000;
    height: 40px;
    width: 40px;
  }
`;

const NavBarOptions = styled.div`
  width: calc(75% - 53px);
  float: left;
  margin-left: 30px;
  margin-top: 2px;
  text-align: right;
`;

const NavList = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  max-width: 50vw;
  @media ${mobileSize} {
    display: none;
  }
`;

interface InvertProp {
  invert?: boolean;
}

const NavListItem = styled.div<InvertProp>`
  /* in case of more overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 25px;
  position: relative;
  overflow: visible;
  color: ${props => (props.invert ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  .active.active {
    &:before {
      content: '.';
      font-size: 30px;
      display: inline-block;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: center;
      width: 100%;
      color: ${theme.PRIMARY_COLOR};
    }
  }
`;

const NavListItemActive = styled.div<InvertProp>`
  /* in case of more overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 25px;
  position: relative;
  overflow: visible;
  color: ${props => (props.invert ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  &:before {
    content: '.';
    font-size: 30px;
    display: inline-block;
    position: absolute;
    bottom: -16px;
    left: 0;
    text-align: center;
    width: 100%;
    color: ${theme.PRIMARY_COLOR};
  }
`;

interface LinkProps {
  invert?: boolean;
  makeblue?: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${props =>
    props.invert ? (props.makeblue ? theme.PRIMARY_COLOR : theme.BLACK_COLOR) : theme.WHITE_COLOR};
  text-decoration: none;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
`;

const OutsideLink = styled.a<LinkProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${props =>
    props.invert ? (props.makeblue ? theme.PRIMARY_COLOR : theme.BLACK_COLOR) : theme.WHITE_COLOR};
  text-decoration: none;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
`;

interface SideNavProps {
  showNav: boolean;
  invert: boolean;
}

const SideNavDiv = styled.div<SideNavProps>`
  display: none;
  @media ${mobileSize} {
    display: ${props => (props.showNav ? 'flex' : 'none')};
    height: 100vh;
    width: 101%;
    opacity: ${props => (props.showNav ? 1 : 0)};
    position: absolute;
    z-index: ${props => (props.showNav ? 998 : -1)};
    left: -1px;
    top: -1px;
    overflow-x: hidden;
    transition: 200ms;
    background-color: ${props => (props.invert ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
    padding-top: 50px;
    a {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 33.75px;
      margin-top: 20px;
    }
  }
`;

const NavigationContainer = styled.div<InvertProp>`
  /* margin-top: 100px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.invert ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
`;

export { NavBar };
