import React, { useState, useEffect } from 'react';
import './FadingText.css';

interface FadingTextProps {
  className?: string;
  interval: number;
  texts?: string[];
}

const FadingText = ({ className, texts = [], interval }: FadingTextProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFadingOut(true);

      setTimeout(() => {
        setIsFadingOut(false);
        setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
      }, 500);
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return (
    <div className={`fading-text-container ${className}`}>
      {texts.map((text, index) => (
        <div
          key={index}
          className={`fading-text ${
            index === currentIndex ? 'fade-in' : isFadingOut ? 'fade-out' : ''
          }`}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default FadingText;
