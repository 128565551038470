import styled from 'styled-components';
import theme from '../../util/theme';

export const BlogH1 = styled.h1`
  margin: 0 0 16px 0;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const BlogH2 = styled.h2`
  margin: 0 0 16px 0;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
`;

export const BlogH3 = styled.h3`
  margin: 0 0 16px 0;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`;

export const BlogH4 = styled.h4`
  margin: 0 0 16px 0;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
`;

export const BlogH5 = styled.h5`
  margin: 0 0 16px 0;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;
