import * as React from 'react'; 
import { Scrambler, Cycler } from 'react-text-scrambler';
import styled from 'styled-components';

interface TextCyclerProps {
  strings: string[];
}
const TextCycler = (props: TextCyclerProps) => {
  return (
    <StyledCyclerContainer>
      <Cycler renderIn={1} duration={4000} strings={props.strings} />
    </StyledCyclerContainer>
  );
};

export { TextCycler };

const StyledCyclerContainer = styled.text`
  color: #45aeeb;
  /* font-weight: bold;
  font-family: 'LL Circular Web', sans-serif;
  font-weight: 700;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: -0.125rem; */
`;
