import styled from 'styled-components';
import theme from '../../util/theme';

export const AppText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  color: ${theme.WHITE_COLOR};
  text-align: left;
  margin: 0;
`;
