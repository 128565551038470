import styled from 'styled-components';
import theme from '../../util/theme';

export const Overline = styled.p<{ white?: boolean }>`
  margin-top: 0px;
  margin-bottom: 16px;
  color: ${props => props.white ? theme.WHITE_COLOR : theme.GREY_800};
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

// const Size = {
//   Large: {
//     size: 20,
//     height: 25,
//   },
//   Large: {
//     size: 20,
//     height: 25,
//   },
//   Large: {
//     size: 20,
//     height: 25,
//   },
//   Large: {
//     size: 20,
//     height: 25,
//   },
  
//   Medium,
//   Small,
//   XSmall,
// }

enum Size {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  XSmall = 'XSmall'
}

interface BlogTextProps {
  size?: 'Large' | 'Medium' | 'Small' | 'XSmall';
  weight?: number;
  color?: string;
  noMargin?: boolean;
}

const handleSize = (size: string | undefined) => {
  switch (size) {
    case Size.Large:
      return 20;
    case Size.Medium:
      return 18;
    case Size.Small:
      return 16;
    case Size.XSmall:
      return 14;
    default:
      return 18;
  }
};

const handleLineHeight = (size: string | undefined) => {
  switch (size) {
    case Size.Large:
      return 25;
    case Size.Medium:
      return 22;
    case Size.Small:
      return 20;
    case Size.XSmall:
      return 18;
    default:
      return 22;
  }
};

export const BlogText = styled.p<BlogTextProps>`
  margin: ${props => props.noMargin ? 0 : ''};
  color: ${props => props.color ? props.color : theme.MEDIUM_GREY};
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => handleSize(props.size)}px;
  font-weight: ${props => props.weight ? props.weight : 400};
  line-height: ${props => handleLineHeight(props.size)}px;
`;
